import React from 'react';
import Banner from "../../Component/Banner";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";


const styles = {
    section: {
        // Buraya stil özellikleri ekleyin
    },
    headerText: {
        marginBottom: 1,
        fontSize:'24px',

    },
    text: {
        // Buraya metin stil özellikleri ekleyin
        marginBottom:20,
    }
};

const GizlilikSartlari = () => {
    return (
        <div>
            <Helmet>
                <title>Gizlilik Şartları</title>
            </Helmet>
            <Banner title="Gizlilik Şartları"/>
            <Container className="mt-5">

                <h2 style={styles.headerText}>Gizlilik Şartlarımız</h2>
                <p style={styles.text}>
                    Biz, Ehliyet Pro uygulaması olarak kullanıcı gizliliğini en yüksek öncelik olarak görmekteyiz. Bu gizlilik politikası, uygulamamızın topladığı verilerin türlerini, bu verilerin nasıl kullanıldığını, kimlerle paylaşıldığını ve kullanıcıların bu süreç üzerindeki kontrol haklarını detaylı olarak açıklamaktadır.
                </p>

                <h2 style={styles.headerText}>Topladığımız Bilgiler</h2>
                <p style={styles.text}>
                    Ehliyet Pro, kullanıcı deneyimini iyileştirmek ve hizmetlerimizi geliştirmek için bazı kullanıcı verilerini toplayabilir. Bu veriler, doğrudan kullanıcıdan alınan kişisel bilgiler veya cihazdan otomatik olarak toplanan bilgiler olabilir. Topladığımız bilgiler arasında şunlar yer alabilir:
                </p>
                <ul>
                    <li><strong>Cihaz Kimlikleri:</strong> Reklam Kimliği (Advertising ID), Android Kimliği (Android ID) gibi cihazınıza ait benzersiz tanımlayıcılar.</li>
                    <li><strong>Kullanım Verileri:</strong> Uygulamanın kullanım sıklığı, hangi sayfaların ziyaret edildiği, hangi özelliklerin ne kadar kullanıldığı gibi bilgiler.</li>
                    <li><strong>Konum Verileri:</strong> Uygulamanın kullanıldığı genel coğrafi bölge (kesin konum değil, şehir veya ülke düzeyinde).</li>
                </ul>

                <h2 style={styles.headerText}>Verilerin Kullanımı</h2>
                <p style={styles.text}>
                    Topladığımız bu veriler, aşağıdaki amaçlarla kullanılmaktadır:
                </p>
                <ul>
                    <li><strong>Kullanıcı Deneyimini İyileştirme:</strong> Kullanıcıların uygulamayı nasıl kullandıklarını anlamak, uygulamanın performansını artırmak ve yeni özellikler geliştirmek için.</li>
                    <li><strong>Reklam ve Analiz:</strong> Reklam gösterimleri ve kullanıcı davranış analizleri için AppLovin gibi üçüncü taraf hizmet sağlayıcılarıyla veri paylaşılabilir. Bu veriler, cihaz kimlikleri ve anonim kullanıcı davranışları içerebilir, ancak kişisel olarak tanımlayıcı bilgiler içermez.</li>
                    <li><strong>Hata ve Performans İzleme:</strong> Uygulama hatalarını tespit etmek, performansı izlemek ve sorunları çözmek için teknik veriler toplanabilir.</li>
                </ul>

                <h2 style={styles.headerText}>Üçüncü Taraf Hizmet Sağlayıcıları</h2>
                <p style={styles.text}>
                    Uygulamamız, aşağıdaki üçüncü taraf hizmet sağlayıcıları ile veri paylaşabilir:
                </p>
                <ul>
                    <li><strong>AppLovin SDK:</strong> Reklam gösterimlerini optimize etmek ve kullanıcıların reklamlarla etkileşimlerini analiz etmek için kullanılmaktadır. AppLovin SDK, kullanıcıların cihaz kimliklerini ve reklam kimliklerini toplayabilir.</li>
                    <li><strong>Google Analytics:</strong> Uygulamanın kullanım istatistiklerini toplamak ve analiz etmek için kullanılır. Bu hizmet kullanıcı davranışlarını izler, ancak kişisel kimlik bilgilerini toplamaz.</li>
                </ul>

                <h2 style={styles.headerText}>Veri Güvenliği</h2>
                <p style={styles.text}>
                    Kullanıcı verilerinin güvenliği bizim için büyük önem taşımaktadır. Toplanan veriler, yetkisiz erişim, değiştirme veya ifşa edilme riskine karşı çeşitli teknik ve idari güvenlik önlemleri ile korunmaktadır. Verileriniz güvenli sunucularda şifrelenmiş olarak saklanır ve yalnızca gerekli durumlarda yetkilendirilmiş personel tarafından erişilebilir.
                </p>

                <h2 style={styles.headerText}>Veri Paylaşımı ve İstisnalar</h2>
                <p style={styles.text}>
                    Kullanıcı verileri, üçüncü taraflarla yalnızca yasal zorunluluklar veya uygulama işleyişi için paylaşılır. Aşağıdaki durumlar dışında verileriniz hiçbir üçüncü tarafla paylaşılmaz:
                </p>
                <ul>
                    <li><strong>Yasal Gereklilikler:</strong> Yasalar çerçevesinde yetkili makamlarca talep edilmesi durumunda kullanıcı verileri paylaşılabilir.</li>
                    <li><strong>İşlem Sağlayıcıları:</strong> Reklam ve analiz hizmetleri sağlayan üçüncü taraflar ile yalnızca anonim ve cihaz düzeyinde veriler paylaşılmaktadır.</li>
                </ul>

                <h2 style={styles.headerText}>Kullanıcı Hakları</h2>
                <p style={styles.text}>
                    Kullanıcılarımızın kişisel verileri üzerinde tam kontrol sahibi olmalarını sağlamak amacıyla çeşitli haklar tanımaktayız. Kullanıcılar, istedikleri zaman bize başvurarak verilerine erişebilir, bu verileri düzeltebilir veya silebilirler. Ayrıca, veri toplama ve işleme süreçleri hakkında daha fazla bilgi almak için bizimle iletişime geçebilirsiniz.
                </p>

                <h2 style={styles.headerText}>İletişim</h2>
                <p style={styles.text}>
                    Gizlilik politikamız veya veri işleme uygulamalarımız hakkında herhangi bir sorunuz varsa, [iletişim bilgileri] üzerinden bizimle iletişime geçebilirsiniz. Gizliliğiniz bizim için önemlidir ve her türlü sorununuzda size yardımcı olmaktan memnuniyet duyarız.
                </p>

            </Container>


        </div>
    );
};

export default GizlilikSartlari;
